import React from "react";
import { PrivacyContent } from "./PrivacyPolicyContent";

function PrivacyPolicy() {
  return (
    <div id="privacy" className="modal fade" role="dialog" aria-hidden="true">
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Privacy Policy</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
            <PrivacyContent />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;

import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { appConfig } from "../config/commonConfig";

const FAQs = () => {
  if (!appConfig.faq) return null;
  const faqsData = [
    {
      question: "How does the Emotion Wheel help in tracking my mood?",
      answer:
        "The Emotion Wheel is a sophisticated tool designed to help you precisely identify and articulate a wide range of emotions. By using this feature, you can gain a deeper understanding of your emotional state, recognize patterns over time, and better manage your emotional well-being.",
    },
    {
      question: `What kind of statistics and insights does the app provide about my emotions?`,
      answer: `MoodSense offers comprehensive statistics, showing each main and sub-emotion by percentage. It also provides a detailed breakdown of how different activities trigger various emotions, enabling you to understand your emotional responses in relation to your daily activities.`,
    },
    {
      question:
        " Is the app really free? Are there any hidden costs or premium features?",
      answer:
        "Yes, MoodSense is completely free. We believe in making emotional wellness accessible to everyone, so there are no hidden costs or premium features. You get full access to all the app's functionalities without any charges.",
    },
    {
      question: "How secure are my diary entries and personal data in the app?",
      answer:
        "We prioritize your privacy and security above all else. All your diary entries and personal data are protected with end-to-end encryption, ensuring that only you have access to your information. Your privacy is our top commitment.",
    },
    {
      question:
        "What if i encounter any issues or have an idea to improve the app?",
      answer: `Committed to continuous improvement and positive
        impact, your feedback is warmly welcome. Whether it's a pat on
        the back or constructive criticism, your thoughts are invaluable
        in helping to enhance MoodSense. Your voice, positive or
        negative, shapes the future of our app.`,
    },
  ];

  return (
    <section id="faq" className="section">
      <div className="container">
        <h2 className="text-9 fw-600 text-center">
          Frequently Asked Questions
        </h2>
        <hr className="heading-separator-line bg-primary opacity-10 mx-auto" />
        <p className="lead text-center mb-4">
          {/* You can present your app FAQ here! */}
        </p>
        <div className="row">
          <div className="col-md-10 col-lg-8 mx-auto">
            <hr className="mb-0 opacity-1" />
            <Accordion flush defaultActiveKey="0" className="arrow-end">
              {faqsData.length > 0 &&
                faqsData.map((faq, index) => (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{faq.question}</Accordion.Header>
                    <Accordion.Body
                      dangerouslySetInnerHTML={{ __html: faq.answer }}
                    ></Accordion.Body>
                  </Accordion.Item>
                ))}
            </Accordion>
            <hr className="mt-0 opacity-1" />
          </div>
        </div>
        <div className="lead text-center mt-4">
          Anything else in mind?{" "}
          <a
            href="#contact"
            className="smooth-scroll link-offset-2 link-underline link-underline-opacity-0-hover"
          >
            Tell us your query here.
          </a>
        </div>
      </div>
    </section>
  );
};

export default FAQs;

import React from "react";
import { HeaderConfig, appConfig, appliedConfig } from "../config/commonConfig";

const featureList = [
  {
    name: "Emotion Wheel Analysis",
    icon: "fas fa-pencil-ruler",
    text: "Explore your emotions with unparalleled precision. ",
  },
  {
    name: "Comprehensive Insights",
    icon: "fas fa-cloud-upload-alt",
    text: "Including activity-based emotion tracking, intensity levels, and weekly summary reports.",
  },
  {
    name: "Free",
    icon: "fas fa-tags",
    text: `Our application is completely free and accessible for everyone.`,
  },
  {
    name: "Encrypted entries",
    icon: "fas fa-lock",
    text: "Your privacy is our top priority. Only you are able to read your entries.",
  },
  {
    name: "Tailored to our users",
    icon: "fas fa-paper-plane",
    text: `Your feedback and reports are not just received, they are
    embraced, and acted upon.`,
  },
  {
    name: "Regular Updates",
    icon: "fas fa-pencil-alt",
    text: "We continuously refine our app with free updates, introducing new features and improvements",
  },
];

const ListItem = ({ name, icon, text, isRight }) => {
  const containerStyle = isRight
    ? "featured-box style-3 mb-5"
    : "featured-box featured-box-reverse-xl style-3 mb-5";
  return (
    <div className="col-md-6 col-lg-12">
      <div className={containerStyle}>
        <div className="featured-box-icon bg-body-secondary rounded-circle">
          <i className={icon} />
        </div>
        <h3>{name}</h3>
        <p>{text}</p>
      </div>
    </div>
  );
};

const Features = () => {
  if (!appConfig.features) return null;
  return (
    <section
      id="features"
      className={
        "section " +
        (appliedConfig.appliedHeader === HeaderConfig.SideHeaderDark ||
        appliedConfig.appliedHeader === HeaderConfig.SideHeaderLight
          ? "px-lg-4 "
          : " ")
      }
    >
      <div className="container">
        <h2 className="text-9 fw-600 text-center">Advanced mood tracking</h2>
        <hr className="heading-separator-line bg-primary opacity-10 mx-auto" />
        <p className="lead text-center mb-5"></p>
        <div className="row">
          <div className="col-lg-4 align-self-center">
            <div className="row justify-content-center">
              {featureList.map((item, index) => {
                if (index > 2) {
                  return null;
                }
                return (
                  <ListItem
                    key={item.name}
                    name={item.name}
                    text={item.text}
                    icon={item.icon}
                  />
                );
              })}
            </div>
          </div>
          <div className="col-lg-4 text-center mb-4 mb-xl-0">
            <img
              alt="mood sense mood tracking app insights"
              src="images/app-showcase-feature.png"
              width="275px"
              className="img-fluid"
            />
          </div>
          <div className="col-lg-4 align-self-center">
            <div className="row justify-content-center">
              {featureList.map((item, index) => {
                if (index < 3) {
                  return null;
                }
                return (
                  <ListItem
                    isRight
                    key={item.name}
                    name={item.name}
                    text={item.text}
                    icon={item.icon}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;

import React from "react";

export const SupportModal = () => {
  return (
    <div id="support" className="modal fade" role="dialog" aria-hidden="true">
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Welcome to MoodSense Support</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
            <h5>Dear User/Visitor,</h5>
            <br />
            <p className="lh-lg">
              Thank you for your interest in MoodSense, your companion in mood
              tracking and emotional well-being. We're proud to let you know
              that our application is completely free. Developed with heartfelt
              dedication and care, our goal is to support your journey towards
              better mental health and self-awareness.
            </p>
            <ul className="lh-lg">
              <li>
                <b>Your Experience Matters to Us</b>
                <br />
                <br />
                As a team committed to continuous improvement and positive
                impact, we warmly welcome your feedback. Whether it's a pat on
                the back or constructive criticism, your thoughts are invaluable
                in helping us enhance MoodSense. Your voice, positive or
                negative, shapes the future of our app.
                <br />
                <br />
              </li>
              <li>
                <b>Encountering Issues? Let Us Know</b>
                <br />
                <br />
                Encountered a glitch? Stumbled upon something unexpected? Please
                don't hesitate to report any bugs or issues you face. We are
                here to ensure a smooth and effective experience for you. Every
                bug report is a stepping stone towards a more robust and
                reliable application.
                <br />
                <br />
              </li>
              <li>
                <b>Together, We Grow</b>
                <br />
                <br />
                Your feedback and reports are not just received; they are
                embraced and acted upon. Each submission is a contribution
                towards a community that values mental health and well-being. By
                sharing your experience, you're helping not just yourself, but
                others who rely on MoodSense for their emotional journey. Feel
                free to reach out through{" "}
                <a href="mailto:hellomoodsense@gmail.com">
                  hellomoodsense@gmail.com
                </a>
                . We are here to listen and assist.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportModal;

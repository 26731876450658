export const HeaderConfig = {
  DefaultHeader: "Default Header",
  ClassicHeader: "Classic Header",
  SideHeaderLight: "Side Header Light",
  SideHeaderDark: "Side Header Dark",
  OverlayMenu: "Overlay Menu",
};

export const IntroThemeConfig = {
  Intro1: "Intro1",
  Intro2: "Intro2",
};

export const IntroBgConfig = {
  Image: "Image",
  Video: "Video",
  Default: "Default",
};

export const IntroContentConfig = {
  Image: "Image",
  Video: "Video",
};

export const appliedConfig = {
  // apply Header : DefaultHeader | ClassicHeader | SideHeaderLight | SideHeaderDark | OverlayMenu
  appliedHeader: HeaderConfig.DefaultHeader,

  // apply Intro theme from : Intro1 | Intro2
  appliedIntro: IntroThemeConfig.Intro2,

  // apply Intro background from : Image | Video | Default
  appliedIntroBg: IntroBgConfig.Default,

  //apply Content in Intro from: Image | Video
  appliedIntroContent: IntroContentConfig.Image,
};

// your Google play store and apple app store links
export const appleAppStoreLink =
  "https://apps.apple.com/de/app/moodsense/id6444242975";
export const googlePlayStoreLink =
  "https://play.google.com/store/apps/details?id=com.moodsenseapp";

// Mailchimp Config (Newsletter)
const mailChimp_u = "712f279694a3b0952ecce7e22";
const mailChimp_id = "b106cd6ec2";

export const mailChimpUrl = `https://moodsenseapp.us12.list-manage.com/subscribe/post?u=${mailChimp_u}&amp;id=${mailChimp_id}&amp;f_id=004d55e0f0`;

// Emailjs Config (Contact Form)
export const emailjsConfig = {
  templateId: "template_iwjixjf",
  serviceId: "service_81tx282",
  userId: "79l_mBle51sjMS1Gn",
};

export const scrollDuration = 1000;

const enableAll = false;

export const appConfig = {
  appStoreLink: appleAppStoreLink,
  googlePlayLink: googlePlayStoreLink,
  home: enableAll || true,
  logo: enableAll || false,
  homeMenu: enableAll || false,
  about: enableAll || false,
  features: enableAll || true,
  screenshot: enableAll || false,
  testimonials: enableAll || false,
  numbers: enableAll || false,
  pricing: enableAll || false,
  faq: enableAll || true,
  contact: enableAll || true,
  download: enableAll || false,
  video: enableAll || false,
  subscribe: enableAll || true,
  socials: enableAll || false,
  getApp: enableAll || true,
};
